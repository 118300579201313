.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.subcategoryMenuContainer {
  border: 1px solid #737373;
  height: 100%;

  & h3 {
    padding: 0.5em;
    color: var(--marketplaceColor) !important;
    border-bottom: 1px solid #737373;
  }

  & ul {
    list-style: none !important;
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-top: 2em;
    padding-top: 0;

    & li a {
      color: var(--colorBlack) !important;
      font-weight: bold;
    }
  }
}
