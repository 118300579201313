@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctaButton {
  background-color: var(--huntingOrange) !important;
  border: 1px solid var(--huntingOrangeDark) !important;

  &:hover {
    background-color: var(--huntingOrangeDark) !important;
  }
}

.landingSubhero {
  min-height: unset;
  height: 40vh;
  max-height: 40vh;
  display: flex;

  @media (--viewportMedium) {
    height: 30vh;
    max-height: 30vh;
  }

  & >div:last-child {
    background: var(--camoColorMedium);
    width: 100%;
    display: flex;
    height: 100%;

    & header {
      position: absolute;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 90vw;

      @media (--viewportMedium) {
        width: 50vw;
      }

      & h2 {
        text-align: center !important;
        margin: 1.5em auto;

        @media (--viewportMedium) {
          margin: 0 auto;
        }
      }

      & a {
        height: 40px;
        margin: 1em auto;
        width: 50vw;
        text-align: center;

        @media (--viewportMedium) {
          width: 20vw;
        }
      }
    }
  }
}
