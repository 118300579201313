.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
}

.processStep {
  background-color: var(--camoColor);
  color: var(--colorWhite);
  display: flex;
  padding: 1em 2em;
  gap: 0.5em;
  height: 100%;
  border-radius: 10px;

  & .processIndex {
    color: var(--camoColor);
    background-color: var(--colorWhite);
    height: 2em;
    width: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 18px;
    border-radius: 50px;
  }

  & h3 {
    font-size: 20px !important;
    color: var(--colorWhite) !important;
  }

  & p {
    margin-top: 0;
    font-size: 16px;
  }
}
