.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

div:has( > div > form.landingSearch) {
  padding: 0 32px;
  margin: 0vw 10vw;
}

form.landingSearch > div {
  padding: 0 !important;
  margin-left: 0;
  margin-right: 0;

  & input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & button > div {
    padding-right: 10px;
    width: 34px;
  }

  & button, & button > div {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

form.categorySearch > div {
  padding: 0 !important;
  margin-left: 0;
  margin-right: 0;

  & input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & button > div {
    padding-right: 10px;
    width: 34px;
  }

  & button, & button > div {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.seeAll {
  text-align: right;
  color: var(--colorWhite);
  cursor: pointer;
  text-transform: capitalize;
}
