.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.clientContainer {
  border: 1px solid rgba(115, 115, 115, 0.24);
  border-radius: 8px;

  & > div:last-child {
    background-color: var(--camoColor);
    color: white;

    padding: 1em 2em;

    h3 {
      font-size: 16px;
      color: white;
    }
  }
}
