.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;
  z-index: 2;

  &:first-child {
    margin-top: 0;
  }
}

.carouselItemContainer {
  position: relative;
  height: 20em;
  border: 1px solid var(--huntingOrange);
  border-radius: 8px;

  & .carouselItemMedia {
    height: 100%;
  }

  & .text {
    position: absolute;
    bottom: 0.5em;
    text-align: center;
    color: var(--colorWhite) !important;
    padding: 0 2em;

    & h3 {
      color: var(--colorWhite);
      border-bottom: 0.2em solid white;
    }
  }

  &:hover {
    box-shadow: 4px 4px 10px 0px gray;
    & h3 {
      color: var(--huntingOrange) !important;
    }
  }
}

.carouselItemMedia:before {
  content: '';
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  z-index: 1;
}
