.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  /* margin-top: 20px; */
  padding: 0 1em;

  &:first-child {
    margin-top: 0;
  }

  & p:first-child {
    font-size: 14px;
  }

  & p:nth-child(2) {
    color: var(--brownColor);
  }

  & p:last-child {
    margin-top: 0;
  }
}

.testimonial {
  display: flex;
  gap: 1em;
  align-items: center;
}
