.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.moneyCard {
  text-align: center;
  h3 {
    color: var(--huntingOrange) !important
  }
}
