.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.subcategoryContainer {
  position: relative;
  height: 25vh;

  & > div, & > div > div, & > div > div > div {
    height: 100%;
  }

  & > div > div > div {
    padding: 0 !important;
  }
}

.subcategoryText {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  padding-right: 5%;
  text-align: center;

  & h3 {
    color: var(--marketplaceColor);
  }
}
