@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.tableContainer {
  width: 95vw;
  overflow-x: auto;
  margin: 0 auto;

  & table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;

    & th, & td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: center;
      white-space: normal;
      min-width: 125px;
      max-width: 125px;
      min-height: 170px;
      height: 170px;
      vertical-align: top;
      padding-top: 40px;
    }

    & th {
      background-color: #f4f4f4;
    }

    & th:first-child, td:first-child {
      position: sticky;
      left: 0;
      background-color: #fff;
      z-index: 2;
    }

    & p {
      font-size: 14px;
      margin: 0;
      padding: 0;
      line-height: 16px;

      &:last-child {
        margin-top: 10px;
      }

      & span {
        font-weight: 800;
        color: #4b4c4c !important
      }
    }

    @media (max-width: 768px) {
      min-width: 500px;
    }
  }
}

.headerTable {
  background-color: #ea5911 !important;
  color: white;
  border-top: 0 !important;
  border-left: 0 !important;
  border-top-left-radius: 10px;
}

.categoryCell {
  background-color: #6e712f !important;
  border-right-color: #7b7c3f !important;
  color: white;
  border-top: 0 !important;

  &:first-child {
    border-left: 1px solid white !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-right: 0 !important;
  }
}

.primaryRow {
  color: white;
  font-weight: 500;

  & td {
    background-color: #4b560e !important;
    border-right: 1px solid #ddd;
  }

  & th {
    background-color: #4e551f !important;
    border-right-color: #4e551f !important;

    &:first-of-type {
      border-left: 1px solid white !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-right: 0 !important;
    }
  }
}

.storeIcon {
  background-color: white;
  border-radius: 54px;
  width: 54px;
  height: 54px;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-width: 48px;
    width: 42px;
  }
}
