@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
  margin-top: 2em;
}

div:has(> .footer) {
  background-color: var(--camoColor);
  padding: 54px 0;
}

.footer {
  /* max-width: var(--contentMaxWidthPages); */
  max-width: 85vw;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 24px 32px;
  color: var(--colorWhite);
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
  color: var(--colorWhite);

  & a {
    color: var(--colorWhite);

    &:hover {
      color: var(--huntingOrange);
    }
  }
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  font-size: 16px;
  line-height: 24px;
}
.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}
.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  /* margin-top: 24px; */

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
.logoWrapper {
  max-width: 370px;
}
.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.contentCol4 {
  & div:last-child {
    div:last-child {
      grid-column: span 2 / span 2;
      padding: 0.5em 0;
    }
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }
  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';

    grid-template-areas: 'logo grid grid grid grid';

    & div:last-child {
      div:last-child {
        grid-column: span 2 / span 2;
        padding: 0.5em 0;
      }
    }
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.additionalFooter {
  background-color: var(--oliveColor);
  color: var(--colorWhite);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0 2em;
  align-items: center;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-around;
  }

  & svg {
    fill: var(--colorWhite) !important;
  }
}

.socialMediaContainer {
  text-align: center;
  grid-column: span 2 / span 2;

  @media (--viewportMedium) {
    grid-column-start: 2;
  }
}
