@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
  align-items: center;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

section:has(.heroSearch) {
  height: 25vh;
  margin-bottom: 10vh;

  & > div:last-child {
    bottom: -10vh;
    position: absolute;
    width: 90vw;
    background-color: var(--oliveColor);
    left: 5vw;
    right: 5vw;
    border-radius: 4px;
  }
}

.blockTitle {
  & h3 {
    font-size: 28px !important;
  }
}
