.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.coverContainer {
  position: relative;
}

.coverItems {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.playAction {
  cursor: pointer;
}
