.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.subcategoryContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2,minmax(0,1fr));
  gap: 1em;
  width: 90vw;
  margin: 0 auto;
}

.subcategoryList {
  grid-row: span 2/span 2;
}

.subcategoryItems {
  grid-column: span 2/span 2;
  display: grid;
  grid-template-columns: repeat(5,minmax(0,1fr));
  gap: 0.5em;
}

.subcategoryImage {
  grid-column: span 2/span 2;
  /* grid-row: span 2/span 2; */
}
