@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.sectionCategoryTitle {
  max-width: unset !important;
  width: 90vw;
  margin: 0 auto !important;
}

.sectionProductsContainer {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1em;
  max-width: unset !important;
  width: 90vw;
  margin: 0 auto;
  grid-auto-rows: 1fr;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.sectionProductsContainerReverse {
  composes: sectionProductsContainer;
  direction: rtl;
}

.mediaBlock {
  grid-column: span 2 / span 2;
  max-height: 35vh;

  & > div {
    height: 100%;

    & > div > div {
      padding: 0 !important;
    }
  }

  & img {
    border-radius: 4px !important;
  }
}
