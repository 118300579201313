@import '../../../../styles/customMediaQueries.css';

.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.landingButtonsContainer {
  text-align: center !important;

  @media (--viewportLarge) {
    text-align: left !important;
  }

  p {
    display: flex;
    gap: 1em;
    flex-direction: column;
    justify-content: center;

    @media (--viewportMedium) {
      flex-direction: row;
    }

    @media (--viewportLarge) {
      justify-content: start;
      flex-direction: row;
    }

    a {
      text-decoration: none;
      padding: 1em;
      border-radius: 4px;
      background-color: white;
      color: black;

      &:first-child {
        background-color: var(--huntingOrange);
        color: white;
      }
    }
  }
}
