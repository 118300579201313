.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.howWorkItemContainer {
  background-color: white;
  border-radius: 8px;
  padding: 1em;

  & div:first-child > div > div {
    width: 2em;
    height: 2em;
    padding-bottom: unset !important;

    img {
      border-radius: 0 !important;
      object-fit: contain;
    }
  }

  h3 {
    color: var(--camoColor) !important;
  }
}

