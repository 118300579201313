.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.subscriptionBlock {
  display: flex;
  align-items: center;
  background: var(--subscriptionBackground);
  padding: 0 2em;
}

div:has(.subscriptionBlock) {
  gap: 0;
}
