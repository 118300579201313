.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.carouselItemContainer {
  position: relative;
  height: 20em;
  border: 1px solid var(--huntingOrange);
  border-radius: 8px;

  & .carouselItemMedia {
    height: 100%;
  }

  & .text {
    position: absolute;
    bottom: 0.5em;
    text-align: center;
    color: var(--colorWhite) !important;
    padding: 0 2em;

    & h3 {
      color: var(--colorWhite);
      border-bottom: 0.2em solid white;
    }
  }

  &:hover {
    box-shadow: 4px 4px 10px 0px gray;
    & h3 {
      color: var(--huntingOrange) !important;
    }
  }
}

.carouselSectionItemContainer {
  border: 1px solid gray;
  border-radius: 4px;

  & .carouselSectionItemMedia {
    height: 12em;
    max-height: 12em;

    & img {
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid gray;
    }
  }

  & .text {
    text-align: center;
    margin: 1em 0 !important;

    & h3 {
      font-size: 16px !important;
      padding: 0 !important;
    }
  }

  &:hover {
    box-shadow: 4px 4px 10px 0px gray;

    & h3 {
      color: var(--huntingOrange) !important;
    }
  }
}

a:hover:has(.carouselSectionItemContainer) {
  text-decoration: none !important;
}
